* {
  backface-visibility: hidden;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
}

@font-face {
  font-family: 'RuddyBVS';
  font-weight: 400;
  src: local('RuddyBVS'),
    url(./assets/font/Ruddy-Regular.ttf) format('truetype');
}
@font-face {
  font-family: 'RuddyBVS';
  font-weight: 800;
  src: local('RuddyBVS'), url(./assets/font/Ruddy-Bold.ttf) format('truetype');
}
@font-face {
  font-family: 'DINProBVS';
  font-weight: 400;
  src: local('DINProBVS'),
    url(./assets/font/DINProBvs400.otf) format('opentype');
}

body {
  background: url(./assets/images/grain.png) #383245;
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  font-family: 'RuddyBVS', calibri, tahoma;
  /* -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
}

.web-specific-font-family {
  font-family: 'RuddyBVS';
  font-weight: 800;
}

.cookiealert {
  padding: 1rem;
  font-family: 'RuddyBVS';
  font-weight: 800;
  text-align: center;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  margin: 0 !important;
  z-index: 999;
  opacity: 0;
  visibility: hidden;
  border-radius: 0;
  /* transform: translateY(100%); */
  /* transition: all 500ms ease-out; */
  color: #ecf0f1;
  background: rgba(0, 0, 0, 0.8);
}

.cookiealert.show {
  opacity: 1;
  transform: translateY(0%);
  visibility: visible;
  /* transition-delay: 1000ms; */
}

.cookiealert a {
  text-decoration: underline;
  color: #ecf0f1;
}

.cookiealert .acceptcookies {
  margin-left: 10px;
  vertical-align: baseline;
}
